import { Tabla, buildData, ChipEstatus, ModalForm, ProgressBar } from 'eureka-design'
import { useEffect, useState } from 'react'
import { capitalize, numberFormat } from '../../js/util'
import { useObtenerAdministradorQuery, useObtenerPadreMutation, useObtenerVentasReporteQuery, useRelacionReporteComisionMutation } from '../../js/services/reporte'
import ModalDatePicker from '../../components/date/ModalDatePicker'
import { NOM_FORM_MOVIMIENTO } from './FormMovimiento'
import { Reporte } from '../../js/comisiones/Reporte'
import { useCrearComisionMutation, useObtenerNivelesMutation } from '../../js/services/comision'
import { useCrearMovimientoMutation, useCrearPeriodoReporteMutation } from '../../js/services/movimiento'
import { useCrearProductoPrecioMutation } from '../../js/services/producto'
import { config } from '../../config/config'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { STATE_DETALLE_MOVIMIENTO } from './DetalleMovimiento'

const NOM_INFO_REPORTE = 'info_reporte'

const Ventas = ({ ShowAlert, Push, UpProps, info_reporte }) => {
    const [usuario] = useState(infoUsuario())

    /** ESTADOS REPORTE */
    const [tipoVenta, fijaTipoVenta] = useState(info_reporte?.tipoVenta ?? '')
    const [fechaInicio, fijaFechaInicio] = useState(info_reporte?.inicio ?? '')
    const [fechaFin, fijaFechaFin] = useState(info_reporte?.fin ?? '')

    const { data:ventasReporte, isLoading:cargandoVentasReporte, refetch:actualizarVentasReporte } = useObtenerVentasReporteQuery({ inicio: fechaInicio, fin: fechaFin, tipo: tipoVenta })
    const { data:administrador, isLoading:cargandoAdministrador } = useObtenerAdministradorQuery({ id: config.CAMVI_ID })
    const [crearComision] = useCrearComisionMutation()
    const [crearReporte] = useCrearPeriodoReporteMutation()
    const [obtenerNiveles] = useObtenerNivelesMutation()
    const [crearMovimieto] = useCrearMovimientoMutation()
    const [relacionReporteComision] = useRelacionReporteComisionMutation()
    const [obtenerPadre] = useObtenerPadreMutation()
    const [crearProducto] = useCrearProductoPrecioMutation()

    /** ESTADOS TABLA */
    const [comenzoReporte, estadoComenzoReporte] = useState(false)

    /** ESTADOS MODAL */
    const [mostrarModalReporte, seguirMostrandoModalReporte] = useState(info_reporte?.progreso > 0)
    const [mostrarModalProgreso, seguirMostrandoModalProgreso] = useState(false)

    /** ESTADOS PROGRESO */
    const [progreso, fijaProgreso] = useState(info_reporte?.progreso ?? 0)
    
    /** HANDLER VENTA */
    const handlerCrearVenta = () => {
        let venta = {}
        Push('formMovimiento', {
            [NOM_FORM_MOVIMIENTO]: {
                ...venta
            }
        })
    }

    const handlerEditarVenta = (item) => {
        let venta = item.item
        let estatus = venta?.cxp_cxc
                                    
        if(estatus === 'pagar') {
            ShowAlert({
                icono: 'error',
                titulo: 'ATENCIÓN',
                mensaje: 'La venta a sido completada, ya no puede ser editada',
                time: 4
            })
        }
        else {
            Push('formMovimiento', {
                [NOM_FORM_MOVIMIENTO]: {
                    ...venta,
                    nomVendedor: venta?.eureka_ac_usuario?.nombre
                }
            })
        }
    }

    const handlerMostrarInformacion = (item) => {
        let venta = item.item
        Push('detalleMovimiento', {
            [STATE_DETALLE_MOVIMIENTO]: {
                ...venta,
                nomVendedor: venta?.eureka_ac_usuario?.nombre
            }
        })
    }

    const handlerEliminar = (item) => {
        let venta = item?.item
        let estatus = venta?.cxp_cxc
        if(estatus === 'liquidado') {
            let conceptos = venta?.eureka_st_transacciones_conceptos
            conceptos.forEach((concepto) => {
                let producto = concepto?.eureka_st_producto
                let detallesProducto = producto?.eureka_st_productos_precios
                let detalleProducto = detallesProducto?.length > 0 ? detallesProducto[0] : null

                crearProducto({
                    productoID: producto?.id,
                    body: { numero: parseInt(detalleProducto.numero) + parseInt(concepto.cantidad) }
                })
            })
        }
        // else restablecer inventario y eliminar comisiones

        crearMovimieto({ id: venta.id, body: { cxp_cxc: 'archivado' } })
        .unwrap()
        .then(() => {
            actualizarVentasReporte()
        })
    }
    
    /** HANDLER REPORTE */
    const handlerGuardarReporte = async () => {
        if(comenzoReporte && ventasReporte.length > 0) {
            // let adminComision = new Reporte(crearMovimieto, crearComision, crearReporte, obtenerNiveles, relacionReporteComision, obtenerPadre, administrador, fechaInicio, fechaFin)
            // let progreso = 0

            // fijaProgreso(progreso)
            // seguirMostrandoModalProgreso(true)

            // await adminComision.inicializarReporte(fechaInicio, fechaFin, tipoVenta, usuario)
            // await adminComision.cargarNiveles()
            // ventasReporte.forEach((venta, i) => {
            //     adminComision.calcularComisiones(venta)

            //     progreso = (i + 1) * 100 / ventasReporte.length
            //     UpProps({ [NOM_INFO_REPORTE]: { ...info_reporte, progreso: progreso } })
            //     fijaProgreso(progreso)
            // })
            // fijaProgreso(100)

            ShowAlert({
                icono: 'success',
                titulo: 'Reporte Creado',
                mensaje: 'El reporte se ha generado correctamente.',
                time: 4,
            })
        }
        else {
            ShowAlert({
                icono: 'error',
                titulo: 'Reporte Rechazado',
                mensaje: 'No hay ventas con las cuales generar el reporte.',
                time: 4,
            })
        }
    }

    const handlerCrearReporte = () => {
        seguirMostrandoModalReporte(true)
    }

    const handlerCancelarReporte = () => {
        fijaTipoVenta('')
        fijaFechaInicio('')
        fijaFechaFin('')

        estadoComenzoReporte(false)
        actualizarVentasReporte()
        UpProps({ [NOM_INFO_REPORTE]: {} })
    }

    if(cargandoVentasReporte || cargandoAdministrador) return
    else {
        let btnsHead = []
        if(tipoVenta?.length > 0 && fechaInicio?.length && fechaFin?.length) {
            btnsHead = [
                {
                    icono: "fa-floppy-disk",
                    tooltip: "Guardar Reporte",
                    onClick: handlerGuardarReporte
                },
                {
                    icono: "fa-rectangle-xmark",
                    tooltip: "Cancelar Reporte",
                    onClick: handlerCancelarReporte
                }
            ]
        }
        else {
            btnsHead = [
                {
                    icono: "fa-plus",
                    tooltip: "Crear",
                    onClick: handlerCrearVenta
                },
                {
                    icono: "fa-calendar-days",
                    tooltip: "Iniciar Reporte",
                    onClick: handlerCrearReporte
                },
            ]
        }

        return (
            <>
                <ModalForm
                    titulo="Creando reporte, por favor espere" 
                    show={mostrarModalProgreso}
                    onClose={() => { 
                        seguirMostrandoModalProgreso(false)
                        handlerCancelarReporte()
                    }}
                >
                    <ProgressBar progress={progreso} size="medium" hard={true} />
                </ModalForm>

                <ModalDatePicker 
                    mostrar={mostrarModalReporte}
                    onClose={seguirMostrandoModalReporte}
                    onChange={(inicio, fin, tipo) => {
                        estadoComenzoReporte(true)
                        UpProps({[NOM_INFO_REPORTE]: { ...info_reporte, inicio: inicio, fin: fin, tipoVenta: tipo }})
                        fijaFechaInicio(inicio)
                        fijaFechaFin(fin)
                        fijaTipoVenta(tipo)
                        actualizarVentasReporte()
                    }}
                    ShowAlert={ShowAlert}
                />
                
                <Tabla 
                    noData={'No hay ventas para mostrar'}
                    checkbox={false}
                    keys={[
                        { text: "Todas", filtro: () => true },
                        { text: "Pendientes", filtro: item => item.item.cxp_cxc === "cobrar" },
                        { text: "Liquidadas", filtro: item => item.item.cxp_cxc === "liquidado" },
                        { text: "Terminadas", filtro: item => item.item.cxp_cxc === "pagar" },
                        { text: "Tipo Afiliadas", filtro: item => item.item.tipo === "afiliación" },
                        { text: "Tipo Compras", filtro: item => item.item.tipo === "compra" },
                    ]}
                    headers={[
                        { text: "Fecha",           key: "fecha",           type: "date" },
                        { text: "Cant. Productos", key: "total_productos", type: "text" },
                        { text: "Total",           key: "total",           type: "text" },
                        { text: "Vendedor",        key: "vendedor",        type: "text" },
                        { text: "Tipo",            key: "tipo",            type: "text" },
                        { text: "Estatus",         key: "cxp_cxc",         type: "text" }
                    ]}
                    data={buildData(ventasReporte, [
                        { key: "fecha", type: "date" },
                        { 
                            key: "total_productos", 
                            type: "text",
                            value: (item) => item?.eureka_st_transacciones_conceptos?.length ?? 0,
                            text: (item) => item?.eureka_st_transacciones_conceptos?.length ?? 0
                        },
                        { 
                            key: "total", 
                            type: "text",
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                let total = 0

                                conceptos.forEach((concepto) => {
                                    total += parseFloat(concepto.costo) * parseFloat(concepto.cantidad)
                                })
                                return numberFormat(total, 2, '.', ',', '$')
                            }
                        },
                        {
                            key: "vendedor",
                            type: "text",
                            text: (item) => capitalize(item.eureka_ac_usuario?.nombre ?? 'sin vendedor'),
                            value: (item) => capitalize(item.eureka_ac_usuario?.nombre ?? 'sin vendedor')
                        },
                        { key: "tipo", type: "text" },
                        {
                            key: "cxp_cxc", 
                            type: "text", 
                            text: (item) => {
                                let texto = ''
                                let tipo = ''
                                if (item.cxp_cxc === "pagar") {
                                    texto = "Periodo Terminado"
                                    tipo = "Aceptado"    
                                }
                                else if (item.cxp_cxc === "liquidado") {
                                    texto = "Liquidado"
                                    tipo = "Aceptado"
                                }
                                else if (item.cxp_cxc === "archivado") {
                                    texto = "Eliminado"
                                    tipo = "Error"
                                }
                                else {
                                    texto = "Pendiente"
                                    tipo = "Pendiente"
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}></ChipEstatus>
                            }
                        }
                    ])}
                    botonesHead={btnsHead}
                    botonesRow={[
                        {
                            icono: "fa-file-edit",
                            tooltip: "Editar",
                            onClick: handlerEditarVenta,
                        },
                        {
                            icono: "fa-circle-info",
                            tooltip: "Información",
                            onClick: handlerMostrarInformacion
                        },
                        {
                            icono: "fa-trash-can",
                            tooltip: "Eliminar",
                            onClick: handlerEliminar
                        }
                    ]}
                />
            </>
        )
    }
}

export default Ventas