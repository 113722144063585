import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from "account-react-eurekasigma"

const TABLA_DISTRIBUIDORES = 'EurekaAcUsuario'
const TABLA_VENTAS = 'EurekaStTransaccione'
const TABLA_REPORTE = 'EurekaStTransaccionesPeriodo'
const TABLA_REPORTE_COMISION = 'EurekaStComisionesPeriodo'
const TABLA_PADRES = 'EurekaBsContacto'

export const reporteAPI = createApi({
    reducerPath: 'reporteAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Reportes'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerVentasReporte: builder.query({
            /**
             * @param {{ inicio: string, fin: string, tipo: string }} data
             */
            query: (data) => {
                let body = new EkQuery(TABLA_VENTAS)
                                .with('eureka_st_comisiones.eureka_ac_comisionista')
                                .with('eureka_st_transacciones_conceptos.eureka_st_producto')
                                .with('eureka_ac_usuario')
                if(data?.inicio?.length > 0 && data?.fin?.length > 0 && data?.tipo?.length > 0) {
                    body = body
                            .where('cxp_cxc', '=', 'liquidado')
                            .where('tipo', '=', data.tipo)
                            .where('fecha', '>=', data.inicio)
                            .where('fecha', '<=', data.fin)
                            .get()

                }
                else body = body
                            .where('cxp_cxc', '!=', 'archivado')
                            .where('tipo',    '!=', 'registro_excel')
                            .get()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearReporte: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_REPORTE)
                if(data.id) {
                    body = body
                            .where('id', data.id)
                            .update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        relacionReporteComision: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REPORTE_COMISION)
                if(data?.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerPadre: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_PADRES)
                                .with('eureka_ac_usuario_contacto.eureka_st_transacciones.eureka_st_transacciones_conceptos')
                                .where('relacion', 'padre')
                if(data?.usuario) {
                    body = body.where('usuario', data.usuario).first()
                }
                else body = body.where('usuario', '').first()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerAdministrador: builder.query({
            /**
             * @param {{ id: number }} data  
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DISTRIBUIDORES)
                if(data?.id) body = body.where('id', data.id).first()
                else body = body.where('id', '').first()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    })
})

export const {
    useObtenerVentasReporteQuery,
    useCrearReporteMutation,
    useRelacionReporteComisionMutation,
    useObtenerPadreMutation,
    useObtenerAdministradorQuery,
} = reporteAPI